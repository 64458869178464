// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  padding: 0px;
  /* height: calc(100vh - 0px); */
  transition: all .5s;
}

/* button {
    color: var(--text-primary);
    background-color: var(--background);
    border: 2px var(--text-primary) solid;
    transition: all .5s;
} */

.popup-content {
  margin: auto;
  background: var(--sidebar-topbar);
  width: 50%;
  padding: 5px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,0BAA0B;EAC1B,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;;;;;GAKG;;AAEH;EACE,YAAY;EACZ,iCAAiC;EACjC,UAAU;EACV,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap\");\n\n.App {\n  color: var(--text-primary);\n  background-color: var(--background);\n  font-size: large;\n  font-weight: bold;\n  padding: 0px;\n  /* height: calc(100vh - 0px); */\n  transition: all .5s;\n}\n\n/* button {\n    color: var(--text-primary);\n    background-color: var(--background);\n    border: 2px var(--text-primary) solid;\n    transition: all .5s;\n} */\n\n.popup-content {\n  margin: auto;\n  background: var(--sidebar-topbar);\n  width: 50%;\n  padding: 5px;\n}\n\n.popup-arrow {\n  color: rgb(255, 255, 255);\n}\n\n[role='tooltip'].popup-content {\n  width: 200px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;\n}\n\n.popup-overlay {\n  background: rgba(0, 0, 0, 0.5);\n}\n\n[data-popup='tooltip'].popup-overlay {\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
