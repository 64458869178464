import React, { useMemo, useEffect, useState } from "react"
import { connect } from "react-redux";
import { allCutomersAction } from "../../../../store/actions/customerActions";
import "../../reacttable/reactTable.css"
import { Col, Row } from "react-bootstrap";
import CustomerGroupingTable from "./../customerGrouping/customerGroupingTable";
import { useTranslation } from "react-i18next";
import { fetchActiveTariffList } from "../../../../store/actions/tariffAction";
import PureBreadcrumbs from "../../breadcrums";
import { fetchAllSubscriptionApi } from "../../../../store/actions/walletActions";
import Select from 'react-select';
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";

function CustomerGrouping(props) {
    const { t } = useTranslation();
    const [groupName, setGroupName] = useState('');
    const [tariffName, setTariffName] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [subscriptionName, setSubscriptionName] = useState('');

    useEffect(() => {
        props.getCustomers();
        props.getActiveTariffList();
        props.fetchAll();
    }, [])

    const sub_name = props.fetchAllRes && props.fetchAllRes.data;
    // console.log(sub_name);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (!e.target.checked) {
            setSubscriptionName('');
        }
    };

    const handleChange = (event) => {
        setGroupName(event.target.value);
    };

    const data = props.list && props.list.customers.filter((item) => item.status === true);
    // console.log("data: ", data);

    const filteredData = data && data.map(item => ({
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        mobile: item.mobile,
        userid: item.userid,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email Id',
                accessor: 'email'
            },
            {
                Header: 'User Name',
                accessor: 'userid'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },

        ],
        []
    );

    const handleTariff = (e) => {
        const code = e.value;
        // console.log("tariff name", code);
        setTariffName(code);
    };

    const options = props.fetchActiveTariffList && props.fetchActiveTariffList.data?.map(item => ({
        value: item.name,
        label: item.name
    }));

    const handleSubscriptionName = (e) => {
        const code = e.value;
        // console.log("tariff name", code);
        setSubscriptionName(code);
    };

    const subNameOptions = props.fetchAllRes && props.fetchAllRes.data?.filter((item) => item.status === 'Active')?.map(item => ({
        value: item.sub_name,
        label: item.sub_name
    }));

    // console.log(DarkThemeStyles)

    return (

        <div className="container-fluid pt-2">
            <PureBreadcrumbs />
            <Row className="">
                <Col lg={12} className="">
                    <div className="customercard mt-2">
                        <div className=""><h4>Customer Grouping</h4></div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Enter Customer Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control gray-border"
                                        value={groupName}
                                        onChange={handleChange}
                                        placeholder="Enter Customer Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Select Tariff")} <span className='mandatory-field'>*</span></label>
                                        <Select
                                            options={options ? options : []}
                                            // className={`${errors.station_name ? 'is-invalid' : ''}`}
                                            name="tariff"
                                            id="floatingInputGridTariff"
                                            placeholder="Select Tariff Name"
                                            styles={DarkThemeStyles}
                                            menuPortalTarget={document.body}
                                            onChange={handleTariff}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mt-3 pl-0 d-flex align-items-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        id="subscriptionValid"
                                        style={{ left: "10rem" }}
                                    />
                                    <label className="form-check-label" htmlFor="subscriptionValid">
                                        {t("Is subscription valid?")}
                                    </label>
                                </div>
                            </Col>
                            <Col lg={6} className="mt-1">
                                {isChecked && (
                                    <div>
                                        <label htmlFor="subscriptionName" className="mt-2 form-label">
                                            {t("Select Subscription Name")}
                                        </label>
                                        <Select
                                            options={subNameOptions ? subNameOptions : []}
                                            name="subscriptionName"
                                            id="floatingInputGridSubscriptionName"
                                            placeholder="Select Subscription Name"
                                            styles={DarkThemeStyles}
                                            menuPortalTarget={document.body}
                                            onChange={handleSubscriptionName}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>

                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ?
                                (<div> <CustomerGroupingTable {...props} isChecked={isChecked} sub_name={subscriptionName} tariffName={tariffName} groupName={groupName} data={filteredData} columns={columns}></CustomerGroupingTable></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        list: state.customer.fetchcutomers,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        fetchAllRes: state.wallet.fetchAllSub,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCustomers: () => dispatch(allCutomersAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        fetchAll: () => dispatch(fetchAllSubscriptionApi()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerGrouping)