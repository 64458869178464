import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import "../../reacttable/reactTable.css"
import { t } from "i18next";
import { viewSingle } from "../../../../store/actions/advertisementAction";
import moment from "moment";
import { allEvseAction } from "../../../../store/actions/evseAction";
import "../advertisement.css"
import { useTranslation } from "react-i18next";


function ViewSingleAdvertise(props) {
    const { t } = useTranslation()
    const { id } = useParams()
    useEffect(() => {
        props.all();
        props.getSingleAdvertiseData(id);
    }, []);

    const data = props.getData && props.getData.data[0]
    // console.log("data", data);
    const stationName = props.list && props.list.data?.filter((station) => station.stationid === data?.stationid)
    // console.log("stationName", stationName);

    const formattedStartDate = (data?.startdate).format('DD/MM/YYYY');
    // console.log(formattedStartDate)
    const formattedEndDate = moment(data?.enddate).format('DD/MM/YYYY');
    // console.log(stationName[0]?.name)

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <div className="row">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("Advertisment Details")} </span>
                </div>
            </div>

            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <br />
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("View Advertisment Details")}</b></Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>

                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>{t("Select Station")}<span className='mandatory-field'>*</span></label>
                                        <select disabled autoFocus className={`form-select form-control`} name="stationid" id="floatingSelectGridU" value={stationName[0]?.name || ''}>
                                            <option value="" >{stationName[0]?.name}</option>
                                        </select>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label>{t("Select EVSE ID")}<span className='mandatory-field'>*</span></label>
                                        <select disabled className={`form-select form-control `} name="evse_id" id="floatingSelectGridevse_id" value={data?.evse_id || ''}>
                                            <option value="">{data?.evse_id}</option>
                                        </select>
                                    </Col>
                                </Row>
                                <br />
                                <div className="form-floating">
                                    <label>{t("Advertisment For")}<span className='mandatory-field'>*</span></label>
                                    <select disabled className={`form-select form-control`} name="advertismentFor" id="floatingSelectGridAdvertismentFor" value={data?.advertismentFor || ''}>
                                        <option value={""}>{data?.advertisement_for}</option>
                                    </select>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>{t("Advertisement Name")} <span className='mandatory-field'>*</span></label>
                                    <input disabled type="text" className={`form-control gray-border `} name="name" id="floatingInputGridname" placeholder="Advertisement Name" value={data?.name || ''} />
                                </div><br />
                                <div className="form-floating">
                                    <label>{t("Description")} <span className='mandatory-field'>*</span></label>
                                    <input disabled type="text" className={`form-control gray-border `} name="description" id="floatingInputGriddescription" placeholder="Description" value={data?.description || ''} />
                                </div><br /><br />

                                <div className="row">
                                    <div className="card sh_ad">
                                        <div className="card-body">
                                            <div className="row sh_ad">
                                                <div className="col-lg-3 col-sm-12">
                                                    <div className="row pad_r1">
                                                        {t("Start date")}  <span className='mandatory-field'>*</span>
                                                    </div>
                                                    <div className="row pad_r1">
                                                        <div className="form-floating">
                                                            <input disabled type="text" className={`form-control gray-border `} name="formattedStartDate" id="floatingInputGridformattedStartDate" placeholder="start date" value={formattedStartDate || ''} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <div className="row pad_r1 mt-1">
                                                        {t("Start Time")}  <span className='mandatory-field'>*</span>
                                                    </div>
                                                    <div className="form-floating">
                                                        <select disabled className={`form-select form-control `} name={`startTime`} id="floatingSelectGridperiod_begin">
                                                            <option value={""}>{data?.starttime}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <div className="row pad_r1">
                                                        {("End date")} <span className='mandatory-field'>*</span>
                                                    </div>
                                                    <div className="row pad_r1">
                                                        <div className="form-floating">
                                                            <input disabled type="text" className={`form-control gray-border `} name="formattedEndDate" id="floatingInputGridformattedEndDate" placeholder="End date" value={formattedEndDate || ''} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <div className="row pad_r1 mt-1">
                                                        {t("End time")} <span className='mandatory-field'>*</span>
                                                    </div>
                                                    <div className="form-floating">
                                                        <select disabled className={`form-select form-control `} name={`endTime`} id="floatingSelectGridperiod_end">
                                                            <option value={""} >{data?.endtime}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row sh_ad">
                                                <div className="col-lg-12 col-sm-12">
                                                    <label>{t("Advertisement Content")} <span className='mandatory-field'>*</span></label>
                                                    <input value={data?.text || ''} disabled name="text" type="text" id="floatingCPText" placeholder="Enter description to be shown on charger" className={`form-control gray-border `} />
                                                </div>
                                            </div>
                                            <div className="row sh_ad">
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="">{t("Advertisement (Image/Video)")}</div>

                                                    <React.Fragment >
                                                        {data?.imagekey === "" ||
                                                            data?.imagekey === "image" ||
                                                            data?.imagekey === "video" ? (
                                                            data?.imagekey === "image" ? (
                                                                <img
                                                                    src={data?.imagevalue}
                                                                    className={`mt-3 object-fit-contain videoContainer`}
                                                                    alt={data?.imagekey}
                                                                />
                                                            ) : data?.imagekey === "video" ? (
                                                                <video
                                                                    src={data?.imagevalue}
                                                                    className={`mt-3 object-fit-contain videoContainer`}
                                                                    loop
                                                                    autoPlay
                                                                    muted
                                                                ></video>
                                                            ) : (
                                                                <b>{t("No advertisement available")}</b>
                                                            )
                                                        ) : null}
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                </br>
                            </form>
                        </Card.Body>
                        <br /> <br />
                    </Card>
                </Col>
                <Col xs={12} md={1}>
                </Col>
            </Row>

        </Container>
    )
}


const mapStateToProps = function (state) {
    return {
        loading: state.advertise.isLoading,
        getData: state.advertise.fetchSingle,
        list: state.evse.allevse,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allEvseAction()),
        getSingleAdvertiseData: (id) => dispatch(viewSingle(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleAdvertise);