import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../breadcrums";
import { fetchCo2ListInsightAction } from "../../../store/actions/insightAction";
import DownloadCo2ReportPdf from "./downloadCo2ReportPdf";
import Table from "../reacttable/table";

function Co2ReductionList(props) {
    const { t } = useTranslation();
    // console.log("props", props);

    useEffect(() => {
        props.getCo2List();
    }, [])

    const data = props.fetchCo2ListRes && props.fetchCo2ListRes.data;
    // console.log("data", data);

    const columns = useMemo(
        () => [
            {
                Header: "Station Name",
                accessor: "stationid",
            },
            {
                Header: "Total Kwh",
                // id: "useridd",
                accessor: "total_kwh",
            },
            {
                Header: "Life time Co2 reduction",
                accessor: "total_co2",
            },

        ],
        []
    );

    return (
        <Container fluid className="">
            <PureBreadcrumbs />
            <br />
            <div className="pt-5">
                <Row className="">
                    <Col>
                        <Card className="customercard">
                            <Card.Header>
                                <Row className="align-items-center">
                                    <Col xs={6} md={6} className="rfid-test">
                                        {t("Station Wise Co2 Reduction Report")}
                                    </Col>

                                    <Col xs={6} md={6} className="text-right">
                                        <DownloadCo2ReportPdf {...props} data={data} />
                                    </Col>
                                </Row>
                            </Card.Header>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>{t("Loading data...")}</h3> :
                                props.fetchCo2ListRes && props.fetchCo2ListRes.data && props.fetchCo2ListRes.data.length !== null ? (
                                    <div>
                                        <Table data={data} columns={columns} />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}
                        </Card>
                    </Col>
                </Row>

            </div>

        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        fetchCo2ListRes: state.insight.fetchCo2List,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCo2List: () => dispatch(fetchCo2ListInsightAction()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Co2ReductionList);
