const DarkThemeStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: 'var(--select-control-background-color-white)', // White
        borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
        color: 'var(--select-control-text-color-black)', // White text color for the control
        // height: 'calc(1.5em + 0.75rem + 2px)',
        // minHeight: 'calc(1.5em + 0.75rem + 2px)',
        '&:hover': {
            borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
        },
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        // height: 'calc(1.5em + 0.75rem + 2px)',
        padding: '0 0.75rem',
        color: 'var(--select-control-text-color-black)', // White text color
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        whiteSpace: 'nowrap',
        color: 'var(--select-placeholder-color-gray)', // Gray placeholder color
    }),
    input: (base) => ({
        ...base,
        color: 'var(--select-control-text-color-black)', // White text color
        border: 'none',
        boxShadow: 'none',
        '& input': {
            border: 'none !important',
            color: 'var(--select-control-text-color-black)', // White text color
        },
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: 'var(--select-menu-background-color-black)', // Black
        color: 'var(--select-menu-text-color-white)', // White text color
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused
            ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
            : 'var(--select-menu-background-color-black)', // Black background
        color: state.isSelected
            ? 'var(--select-option-selected-text-color-white)' // White if selected
            : 'var(--select-menu-text-color-white)', // White otherwise
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
        },
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: 'var(--multi-value-text-color-white)', // White text color
    }),
    multiValueRemove: (base) => ({
        ...base,
        color: 'var(--multi-value-text-color-white)', // White text color
        ':hover': {
            backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
            color: 'var(--multi-value-text-color-white)', // White text color
        },
    }),
    clearIndicator: (base) => ({
        ...base,
        color: 'var(--clear-indicator-color-white)', // White
        ':hover': {
            color: 'var(--clear-indicator-color-white)', // White on hover
        },
        cursor: 'pointer'
    }),
    singleValue: (base) => ({
        ...base,
        color: 'var(--select-control-text-color-black)', // White text color
    }),
};

export default DarkThemeStyles;
