import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const MyDocument = (props) => {
    const { t } = useTranslation();
    const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
    const { data } = props;
    // console.log("data", data);
    // console.log(data === null)

    const generatePDF = () => {
        const unit = "pt";
        const size = "a4"; // Use 'a4', 'letter', or other standard page sizes
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);
        // console.log("props", props)
        const { data } = props;
        const { organisation } = JSON.parse(localStorage.getItem('user'));
        const org_name = organisation === "All" ? "All" : organisation;
        const organisation_name = "Organisation: " + org_name;
        const title = "Title: All Transactions Details";
        const date = "Date: " + new Date().toLocaleString().split("T")[0];
        const org_unit = "Total kWh: " + data?.total_energy;
        const org_amt = "Total Revenue: " + data?.total_revenue;
        const gst_number = "GST Number: " + gstNumber;

        const headers = [
            ["Station Name", "Address", "Charging Date", "Consume Time", "Connector Type", "Unit Consumed", "Total Amount"]
        ];

        const bodyData = data?.map(elt => [
            elt.stationid,
            elt.location,
            elt.start,
            elt.consume_time,
            elt.standard,
            elt.kwh,
            elt.total_amount
        ]);

        doc.text(title, 40, 20);
        doc.text(organisation_name, marginLeft, marginTop);
        doc.text(date, marginLeft, marginTop + 20);
        doc.text(date, marginLeft, marginTop + 20);

        doc.autoTable({
            startY: marginTop + 100,
            head: headers,
            body: bodyData,
            theme: 'grid', // 'striped', 'grid', 'plain'
            styles: { halign: 'center' },
            columnStyles: {
                0: { halign: 'left' }
            }
        });

        doc.save(`${org_name}_Transaction_data.pdf`);
    };

    const handleButtonClick = () => {
        generatePDF();
        // props.onClick();
    };

    return (
        <div>
            <button className='pdfbutton' disabled={data === null ? true : false} onClick={handleButtonClick} type="button">{t("PDF(CUSTOMER)")}</button>
        </div>
    );
};

export default MyDocument;
