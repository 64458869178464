import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

// Create Document Component
const VendorInvoiceDocument = (props) => {
  const { t } = useTranslation();
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
  const generatePDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const org_name = props.org == "All" ? "All" : props.org
    const { organisations } = props.orgdata
    const organisation = organisations && organisations.filter(or => or.name == org_name)
    // const {address,city,email,phone}=organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
    const title = "Organisation: " + org_name;
    // const org_add="Address: "+address;
    // const org_city="City: "+city
    // const org_email="Email: "+email
    // const org_ph="Phone: "+phone.toString()
    const v_amt = (props.csvDataRes?.total_revenue)
    const tax = ((v_amt * 15) / 100)
    const org_unit = "Total kWh: " + props.csvDataRes?.total_energy
    const org_amt = "Total Amount: " + v_amt.toFixed(2)
    const org_tax = "Tax Amount: " + tax.toFixed(2)
    const total_amt = "Total Payable(Amount + Tax): " + "$" + (v_amt + tax).toFixed(2)
    const gst_number = "TRN No. : " + gstNumber;

    const headers = [["Date", "Station Name", "City", "Start time", "End time", "Charging time (HH:MM:SS)", "Total kWh", "Total Amount"]];
    const data = props.csvDataRes?.data.map(elt => [elt.end_date, elt.stationid, elt.city, elt.start_time, elt.stop_time, elt.consume_time, elt.kwh, elt.total_amount]);

    let content = {
      startY: 210,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    // doc.text(org_add, marginLeft, 55);
    // doc.text(org_city, marginLeft, 70);
    // doc.text(org_email, marginLeft, 85);
    // doc.text(org_ph, marginLeft, 100); 
    doc.text(range, marginLeft, 115);
    doc.text(org_unit, marginLeft, 135);
    doc.text(gst_number, marginLeft, 100);
    // doc.text(org_amt, marginLeft, 155); 
    // doc.text(org_tax, marginLeft, 175); 
    // doc.text(total_amt, marginLeft, 195).setFillColor(25, 135, 84,0); 
    doc.autoTable(content);
    doc.save(props.org + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }

  const handleButtonClick = async () => {
    // Generate the PDF
    generatePDF();
    props.onClick();
  };

  return (
    <div>
      <button onClick={handleButtonClick} className='pdfbutton' type="button">{t("PDF(Partner)")}</button>
    </div>
  )
}

export default VendorInvoiceDocument;