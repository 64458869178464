// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfbutton {
    background: #434343;
    border-color:  #434343 !important;
    /* box-shadow: 0px 4px 8px rgba(92, 92, 92, 0.25); */
    border-radius: 52px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/pdf/pdf.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iCAAiC;IACjC,oDAAoD;IACpD,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".pdfbutton {\n    background: #434343;\n    border-color:  #434343 !important;\n    /* box-shadow: 0px 4px 8px rgba(92, 92, 92, 0.25); */\n    border-radius: 52px;\n    color: rgb(255, 255, 255);\n    font-size: 14px;\n    height: 38px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
