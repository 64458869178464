import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchCountryCode } from '../store/actions/insightAction';
import DarkThemeStyles from './dashboard/resuableComponent/reactSelectSingleValueStyle';

function LanguageSwitcher(props) {
    const { i18n } = useTranslation();
    // console.log(props)
    useEffect(() => {
        props.getCC()
    }, [])

    const data = props.getCode && props.getCode?.country;
    // console.log(props.getCode && props.getCode?.country)

    const languagePlaceholderMap = data && data.reduce((acum, lang) => {
        // console.log(acum);
        acum[lang.code] = { label: lang.name, icon: <img src={lang.icon} alt={lang.code} style={{ width: '15px', height: '15px' }} /> };
        return acum;
    }, {});

    const placeholderObj = languagePlaceholderMap ? languagePlaceholderMap[i18n.language] || languagePlaceholderMap.en : null;
    // const userLanguage = window.navigator.languages;
    // console.log(languagePlaceholderMap)
    const customPlaceholder = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {placeholderObj && placeholderObj.icon}
            <span style={{ marginLeft: 5 }}>{placeholderObj && placeholderObj.label}</span>
        </div>
    );

    const handleChangeLanguage = (e) => {
        const selectedLanguage = e.code;
        // console.log(selectedLanguage)
        localStorage.setItem("LocalLanguage", selectedLanguage)
        props.onChangeLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    };
    return (
        <Select
            className='form-select country_select'
            placeholder={customPlaceholder}
            options={props.getCode && props.getCode?.country}
            onChange={handleChangeLanguage}
            isSearchable={false}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: 'var(--select-control-background-color-black)', // black
                    borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                    color: 'var(--select-control-text-color-white)', // White text color for the control
                    // height: 'calc(1.5em + 0.75rem + 2px)',
                    // minHeight: 'calc(1.5em + 0.75rem + 2px)',
                    '&:hover': {
                        borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
                    },
                }),
                valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    // height: 'calc(1.5em + 0.75rem + 2px)',
                    padding: '0 0.75rem',
                    color: 'var(--select-control-text-color-gray)', // White text color
                }),
                placeholder: (baseStyles) => ({
                    ...baseStyles,
                    whiteSpace: 'nowrap',
                    color: 'var(--color-white)', // Gray placeholder color
                }),
                input: (base) => ({
                    ...base,
                    color: 'var(--select-control-text-color-black)', // White text color
                    border: 'none',
                    boxShadow: 'none',
                    '& input': {
                        border: 'none !important',
                        color: 'var(--select-control-text-color-black)', // White text color
                    },
                }),
                menu: (base) => ({
                    ...base,
                    backgroundColor: 'var(--select-menu-background-color-black)', // Black
                    color: 'var(--select-menu-text-color-white)', // White text color
                }),
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                        ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
                        : 'var(--select-menu-background-color-black)', // Black background
                    color: state.isSelected
                        ? 'var(--select-option-selected-text-color-white)' // White if selected
                        : 'var(--select-menu-text-color-white)', // White otherwise
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                    },
                }),
                multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: 'var(--multi-value-text-color-white)', // White text color
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    color: 'var(--multi-value-text-color-white)', // White text color
                    ':hover': {
                        backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                        color: 'var(--multi-value-text-color-white)', // White text color
                    },
                }),
                clearIndicator: (base) => ({
                    ...base,
                    color: 'var(--clear-indicator-color-white)', // White
                    ':hover': {
                        color: 'var(--clear-indicator-color-white)', // White on hover
                    },
                    cursor: 'pointer'
                }),
                singleValue: (base) => ({
                    ...base,
                    color: 'var(--select-control-text-color-white)', // White text color
                }),
            }}
            menuPortalTarget={document.body}
            formatOptionLabel={item => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={`${item.icon}`} alt={item.code} style={{ width: '15px', height: '15px' }} />
                    <span style={{ marginLeft: 5, marginRight: 5 }} >{item.name}</span>
                </div>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        getCode: state.insight.getCountryCode,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCC: () => dispatch(fetchCountryCode()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);

// export default LanguageSwitcher;
