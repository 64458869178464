import { Container, Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { allCutomersAction, allRFIDAction, createRfidbindingApi, fetchRfidList } from "../../../../store/actions/customerActions";
import RfidList from "./rfidList";
import Popup from "reactjs-popup";
import { ImCross } from "react-icons/im";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import Select from 'react-select';

function RfidCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.getRfid();
    props.getCustomers();
    props.getAllCurrency();
    props.all();
  }, []);

  const data = props.list && props.list.customers
  // console.log("data", data);

  const rfidList = props.rfidList && props.rfidList.rfids;
  // console.log(rfidList);

  const rfidOptions = rfidList && rfidList.filter((item) => item.status !== 'Inactive')?.map(item => ({
    value: item.rfid,
    label: item.rfid
  }));

  const userNameOptions = data && data.filter((item) => item.status !== false)?.map(item => ({
    value: item.userid,
    label: item.userid
  }));

  const rfidSchema = Yup.object().shape({
    username: Yup.string().required('UserName is required'),
    rfidNo: Yup.string().required('Rfid number is required'),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(rfidSchema), mode: "all" });


  const onSubmit = async data => {
    // console.log(data)
    props.saveRfid(data)
  };

  if (props.saveRfidRes.statuscode === 200) {
    alert("Rfid binding with customer is saved successfully")
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 406) {
    alert(props.saveRfidRes.result)
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 404) {
    alert(props.saveRfidRes.error)
    window.location.reload();
  }
  if (props.saveRfidRes.statuscode === 405) {
    alert(props.saveRfidRes.error)
    window.location.reload();
  }

  const handleChange = selectedOption => {
    // console.log('Selected RFID:', selectedOption.value);
    setValue("rfidNo", selectedOption.value);
  };

  const handleUserChange = selectedOption => {
    // console.log('Selected RFID:', selectedOption.value);
    setValue("username", selectedOption.value);
  };


  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row className="align-items-center">
                <Col xs={6} md={6} className="rfid-test">
                  {t("Customer Binded RFID List")}
                </Col>

                <Col xs={6} md={6} className="text-right">
                  <Popup
                    trigger={
                      <button
                        className="lgn-btn btn"
                        type="button"
                        style={{ position: "relative", width: "auto", height: "auto" }}
                      >
                        {t("Bind Rfid With Customer")}
                      </button>
                    }
                    modal
                    closeOnDocumentClick={false}
                    contentStyle={{
                      borderRadius: "10px",
                      padding: "20px",
                      width: window.innerWidth > 600 ? "50%" : "80%", // Adjust width based on screen size
                      margin: "auto",
                      maxHeight: "80vh",
                      overflow: "auto"
                    }}
                    className="popup-content"
                  >
                    {(close) => (
                      <>
                        <div style={{ textAlign: "right" }}>
                          <button className="close-btn" onClick={() => window.location.reload()}>
                            <span>
                              <ImCross />
                            </span>
                          </button>
                        </div>
                        <div className="customercard">
                          <h2 style={{ textAlign: "center" }}>{t("Bind RFID With Customer")}</h2>

                          <Form className="p-3 mt-4" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={6} className="mb-3">
                                <Form.Label>{t("User Name")}</Form.Label>
                                <Select
                                  options={userNameOptions ? userNameOptions : []}
                                  className={` ${errors.username ? 'is-invalid' : ''}`}
                                  name="username"
                                  id="floatingInputGridusername"
                                  placeholder="Select User Name"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ? 'var(--dark-background)' : 'var(--dark-background)',
                                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',

                                      height: 'calc(1.5em + 0.75rem + 2px)',
                                      minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                      zIndex: '9999'
                                    }),
                                    valueContainer: (baseStyles) => ({
                                      ...baseStyles,
                                      height: 'calc(1.5em + 0.75rem + 2px)',
                                      padding: '0 0.75rem',
                                    }),
                                    placeholder: (baseStyles) => ({
                                      ...baseStyles,
                                      whiteSpace: 'nowrap',
                                    }),
                                    input: base => ({
                                      ...base,
                                      border: 'none',
                                      boxShadow: 'none',
                                      '& input': {
                                        border: 'none !important',
                                      },
                                    }),
                                    menu: (base, state) => ({
                                      ...base,
                                      width: "auto",
                                      backgroundColor: state.isFocused ? 'white' : 'black',

                                    }),
                                    menuPortal: (base, state) => ({
                                      ...base,
                                      width: "auto",
                                    }),
                                    option: (base, state) => ({
                                      ...base,
                                      backgroundColor: state.isFocused ? 'black' : 'black', // Background color on hover
                                      cursor: 'pointer',
                                    }),
                                  }}
                                  onChange={handleUserChange}
                                />
                                <div className="invalid-feedback">{errors.username?.message}</div>
                              </Col>

                              <Col md={6} className="mb-3">
                                <Form.Label>{t("RFID No.")}</Form.Label>
                                <Select
                                  options={rfidOptions ? rfidOptions : []}
                                  className={`w-100 ${errors.rfidNo ? 'is-invalid' : ''}`}
                                  name="rfidNo"
                                  id="floatingInputGridrfidNo"
                                  placeholder="Select RFID Number"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ? 'var(--dark-background)' : 'var(--dark-background)',
                                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',

                                      height: 'calc(1.5em + 0.75rem + 2px)',
                                      minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                      zIndex: '9999'
                                    }),
                                    valueContainer: (baseStyles) => ({
                                      ...baseStyles,
                                      height: 'calc(1.5em + 0.75rem + 2px)',
                                      padding: '0 0.75rem',
                                    }),
                                    placeholder: (baseStyles) => ({
                                      ...baseStyles,
                                      whiteSpace: 'nowrap',
                                    }),
                                    input: base => ({
                                      ...base,
                                      border: 'none',
                                      boxShadow: 'none',
                                      '& input': {
                                        border: 'none !important',
                                      },
                                    }),
                                    menu: (base, state) => ({
                                      ...base,
                                      width: "auto",
                                      backgroundColor: state.isFocused ? 'white' : 'black',

                                    }),
                                    menuPortal: (base, state) => ({
                                      ...base,
                                      width: "auto",
                                    }),
                                    option: (base, state) => ({
                                      ...base,
                                      backgroundColor: state.isFocused ? 'black' : 'black', // Background color on hover
                                      cursor: 'pointer',
                                    }),
                                  }}
                                  onChange={handleChange}
                                />
                                <div className="invalid-feedback">{errors.rfidNo?.message}</div>
                              </Col>
                            </Row>

                            <Row className="justify-content-center mt-2 mb-4">
                              <Col lg={6} className="d-flex justify-content-center">
                                <button
                                  className="lgn-btn btn"
                                  type="submit"
                                  disabled={props.loading}
                                >
                                  {props.loading ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      &nbsp;
                                      {t("Saving...")}
                                    </>
                                  ) : (
                                    <p>
                                      {t("Save")}
                                    </p>
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Form>

                        </div>
                      </>
                    )}
                  </Popup>
                </Col>
              </Row>
            </Card.Header>
            <RfidList {...props} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    rfidList: state.customer.fetchAllRFID,
    fetchRfidListRes: state.customer.fetchRfidList,
    list: state.customer.fetchcutomers,
    saveRfidRes: state.customer.saveRfidBindings,
    allcurrency: state.tariff.fetchcurrency,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allRFIDAction()),
    getRfid: () => dispatch(fetchRfidList()),
    getCustomers: () => dispatch(allCutomersAction()),
    saveRfid: (data) => dispatch(createRfidbindingApi(data)),
    getAllCurrency: () => dispatch(allCurrencyAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RfidCard);
