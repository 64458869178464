import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import ChargerReportTable from "./adminReport/chargerReportTable";
import { fetchChargerReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import { useTranslation } from "react-i18next";
import ChargerReportPdf from "./chargerReportPdf";

function ChargerReport(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.chargerReportApi()
    }, [])

    const data = props.charger_report && props.charger_report.data;
    // console.log("data", data);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col xs={12} md={12}>
                    <Card className="customercard mt-3">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={6} md={6} className="rfid-test">
                                    {t("Charger Report")}
                                </Col>

                                <Col xs={6} md={6} className="text-right">
                                    <ChargerReportPdf {...props} data={data} />
                                </Col>
                            </Row>
                        </Card.Header>
                        {/* <div className="table-header">{t("Charger Report")}</div> */}
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ? (
                                <div>
                                    <ChargerReportTable {...props} />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "300px",
                                    }}
                                >
                                    <h3>No Data Found</h3>
                                </div>
                            )}

                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        charger_report: state.report.chargerReport,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        chargerReportApi: () => dispatch(fetchChargerReport()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerReport);
