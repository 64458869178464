import { t } from "i18next";
import React from "react";
import ApexCharts from "react-apexcharts";
import { Card } from "react-bootstrap";


function CustomStationEnergy(props) {
  const data = props.date_range_energy && props.date_range_energy.data;
  // console.log(data)
  const sortedData = [...data] && [...data].sort((a, b) => b.energy - a.energy);
  const top5Station = sortedData && sortedData.slice(0, 5);
  // console.log(top5Station)

  const monthdata = {
    chart: {
      type: "area",
      height: 350,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
         }
       },  
      yaxis: {
        lines: { 
          show: false
         }
       },   
    },
    xaxis: {
      categories: top5Station && top5Station.map((item) => (item.stationid)),
      labels: {
        style: {
          colors: "#FFFFFF", // Replace with the color you want for Y-axis content
        },
      },    
    },
    yaxis: [
      {
        title: {
          text: "Monthly Consumption",
          style: {
            color: "#FFFFFF",
            fontSize:"13px",
            fontWeight: 600,
        },
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          style: {
            colors: "#FFFFFF", // Replace with the color you want for Y-axis content
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#0BCF6B"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    series: [
      {
        name: "Monthly Consumption",
        data: top5Station && top5Station.map((item) => (item.energy)),
        yaxis: 1,
      },
    ],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      followCursor: false,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
  };

  return (
    <Card className="statuscard">
      <Card.Title className="text-center">
        {t("Station Energy Graph")}
      </Card.Title>
      <Card.Body>
        {props.loading ? <h3 style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "250px",
        }}>Loading data...</h3> :
          top5Station && top5Station.length > 0 ? (

            <ApexCharts
              options={monthdata}
              series={monthdata.series}
              type="area"
              height={250}
            />) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}
            >
              <h3>{t("No data found")} </h3>
            </div>
          )}
      </Card.Body>
    </Card>
  )
}


export default CustomStationEnergy;
