// This component is preventing input field from entering 0 ,-ve and non-numeric values & characters.

export const HandleKeyPress = (e) => {
    const value = e.target.value;
    // Prevent entering '0' as the first character
    if (e.key === '.' && value === '') {
        e.preventDefault();
    }
    // Prevent entering non-numeric characters and negative sign
    if (e.key === '-' || e.key === 'e') {
        e.preventDefault();
    }
};