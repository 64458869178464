import { Container, Row, Col, Card, Button } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { allEvseAction, cacheRefreshApi, fetchEVSEPercentageChangeAction, firstClearCacheApi, saveRoamingStation, secondClearCacheApi } from "../../../../store/actions/evseAction";
import EVSEList from "./evseList";
import { ImCross } from "react-icons/im";
import Popup from "reactjs-popup";
import * as Yup from 'yup';
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import EVSEMap from "./viewEvseMap";

function ViewEVSE(props) {
  const { t } = useTranslation();
  const { role } = JSON.parse(localStorage.getItem("user"));
  const latitude = process.env.REACT_APP_COUNTRY_LATITUDE;
  const longitude = process.env.REACT_APP_COUNTRY_LONGITUDE;
  // console.log("latitude: " + latitude + ", longitude: " + longitude);

  useEffect(() => {
    props.all();
    props.fetchEvsePercentageChange();
  }, []);

  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(4.8);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        setZoom(4.3);
      },
      (error) => {
        console.error('Error getting user location:', error);
        setUserLocation({
          lat: Number(latitude),
          lng: Number(longitude)
        });
        setZoom(12);
      }
    );
  }, []);

  const AddRoamingSchema = Yup.object().shape({
    roaming_station: Yup.array().of(
      Yup.object().shape({
        stationid: Yup.string().required('Roaming station is required'),
      })
    )
  });

  const { register, control, handleSubmit } = useForm({ resolver: yupResolver(AddRoamingSchema), mode: "all" });
  const { fields, append, remove } = useFieldArray({ name: 'roaming_station', control });
  // console.log("fields", fields)

  const onSubmit = async data => {
    // console.log("save", data.roaming_station)
    props.addRoamingStation(data.roaming_station)
  };

  if (props.addRoaming.statuscode === 200) {
    alert("Roaming Stations have been added successfully");
    window.location.reload();
  }


  const total = props.list && props.list.data?.filter((item) => item.status !== "Discovery")?.length;
  const activec = props.list && props.list.data?.filter((lc) => lc.status === "Available");
  const comingsoon = props.list && props.list.data?.filter((lc) => lc.status === "Coming soon");
  const maintenance = props.list && props.list.data?.filter((lc) => lc.status === "Maintenance");
  // console.log("station list", props.list && props.list)

  const Total_location_percentage = props.percentagechange && props.percentagechange.total_location_percentage
  const Total_location_change_symbol = props.percentagechange && props.percentagechange.change_symbol

  const Available_location_percentage = props.percentagechange && props.percentagechange.available_percentage
  const Available_location_change_symbol = props.percentagechange && props.percentagechange.available_change_symbol

  const Comingsoon_percentage = props.percentagechange && props.percentagechange.comingsoon_percentage
  const Comingsoon_change_symbol = props.percentagechange && props.percentagechange.comingsoon_change_symbol

  const Inoperative_percentage = props.percentagechange && props.percentagechange.inoperative_percentage
  const Inoperative_change_symbol = props.percentagechange && props.percentagechange.inoperative_change_symbol

  const handleClear = () => {
    props.clearCacheApi();
    console.log("Clear Cache");
  }

  useEffect(() => {
    if (props.clearCacheRes.statuscode === 200) {
      alert("Cache is cleared");
      window.location.reload();
    } else if (props.clearCacheRes.statuscode === 405) {
      alert("Error while refreshing stations.");
      window.location.reload();
    }
  }, [props.clearCacheRes]);

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />

      <Row>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("TOTAL LOCATION")}</Card.Text>
              <Card.Title>
                <b>{total !== undefined ? total : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_location_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_location_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/evse/totallocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("AVAILABLE")}</Card.Text>
              <Card.Title>
                <b>{activec !== undefined ? activec.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Available_location_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Available_location_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/availablelocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("COMING SOON")}</Card.Text>
              <Card.Title>
                <b>{comingsoon !== undefined ? comingsoon.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Comingsoon_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Comingsoon_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/commingssoonlocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("OUT OF ORDER")}</Card.Text>
              <Card.Title>
                <b>{maintenance !== undefined ? maintenance.length : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Inoperative_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Inoperative_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/evse/inoperativelocation.svg"
                    }
                    alt="evse"
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>
      {/* <Row className="mt-0 d-flex justify-content-end">
        <Popup
          trigger={
            <button
              className="lgn-btn btn mr-3"
              type="button"
              variant="primary"
              style={{ width: '20%' }}
            >
              {t("Add Roaming Station")}
            </button>
          }
          modal
          closeOnDocumentClick={false}
          contentStyle={{
            borderRadius: "10px",
            padding: "20px",
            width: "50%",
            margin: "auto",
            maxHeight: "80vh",
            overflow: "auto"
          }}
          className="popup-content"

        >
          {(close) => (
            <>
              <div style={{ textAlign: "right" }}>
                <button className="close-btn" onClick={() => window.location.reload()}>
                  <span><ImCross></ImCross></span>
                </button>
              </div>
              <div className="tariffcard">
                <h2 style={{ textAlign: "center" }}>{t("Add Roaming Station")}</h2>
                <div className="">
                  <div className="d-flex justify-content-end">
                    <Button className="mr-2 add-btn" style={{ width: "10%" }}
                      onClick={() => { append({ stationid: '' }) }} > {t("Add")} + </Button>
                  </div>
                </div>
                <Row className="add-roaming-station">
                  <form className="p-3 mt-4" onSubmit={e => e.preventDefault()}>
                    {fields.map((item, i) => (
                      <div key={i} className="mb-3">
                        <div className="row">
                          <div className="col-3 d-flex align-items-center">
                            <b>{t("Station")} {i + 1}</b>
                          </div>
                          <div className="col-6">
                            <select
                              className={`form-select form-control`}
                              name={`roaming_station[${i}].stationid`}
                              id={`floatingSelectGridstationid-${i}`}
                              {...register(`roaming_station.${i}.stationid`)}
                            >
                              <option value="">{t("Select Roaming Station")}</option>
                              {props.list && props.list.data?.map((v) => (
                                <option key={v.stationid} value={v.stationid}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-3">
                            <Button
                              className="ml-1"
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(i);
                              }}
                            >
                              -
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {fields && fields?.length > 0 && <div className="popup-btn">
                      <button
                        className="lgn-btn second-button"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {t("Save")}
                      </button>
                    </div>}
                  </form>
                </Row>
              </div>
            </>
          )}
        </Popup>
      </Row> */}

      <Row className="mt-5">
        <Col>
          <Card className="customercard">
            <div >
              <EVSEMap stations={props.list?.data} center={userLocation} zoom={zoom} />
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="d-flex justify-content-between">
              <div className={`table-header d-flex align-items-center `}>
                {t("EVSE List")}
              </div>
              <button
                className="lgn-btn btn"
                type="button"
                style={{ position: "relative", width: "auto", height: "auto" }}
                onClick={handleClear}
              >
                {t("Refresh Cache")}
              </button>
            </div>
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "250px",
            }}>Loading data...</h3> :
              props.list && <EVSEList {...props}></EVSEList>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.allevse,
    percentagechange: state.evse.evsepercentagechange,
    addRoaming: state.evse.saveRoamingStation,
    clearCacheRes: state.evse.clearCache,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
    fetchEvsePercentageChange: () => dispatch(fetchEVSEPercentageChangeAction()),
    clearCacheApi: () => dispatch(cacheRefreshApi()),
    addRoamingStation: (stationid) => dispatch(saveRoamingStation(stationid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewEVSE);
