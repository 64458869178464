import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Card, Row, Col, Container, Form } from "react-bootstrap";
import { allRFIDAction, fetchSingleRfidApi, updateRfidAction } from "../../../../store/actions/customerActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { allCurrencyAction } from "../../../../store/actions/tariffAction";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import "../evse.css";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { allEvseAction } from '../../../../store/actions/evseAction';
import ReactSelect from 'react-select';
import moment from 'moment';

const ViewSingleRfid = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [isAmountBased, setIsAmountBased] = useState(false);
    const [isRechargeAmountFree, setIsRechargeAmountFree] = useState(false);
    const [iskWhBased, setIskWhBased] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [rfidRechargeAmount, setRfidRechargeAmount] = useState('');
    const [creditDebitAmount, setCreditDebitAmount] = useState('');
    const [kWhUsage, setkWhUsage] = useState('');
    const [kWhAmount, setKWhAmount] = useState('');
    const [isTimeBased, setIsTimeBased] = useState(false);
    const [time, setTime] = useState('');
    const [timeRechargeAmount, setTimeRechargeAmount] = useState('');
    const [rfidType, setRfidType] = useState('');
    const [postpaidDuration, setPostpaidDuration] = useState('');


    useEffect(() => {
        props.getSingleRfidData(id);
        props.allEvse();
    }, []);

    const rfidSchema = Yup.object().shape({
        rfid: Yup.string(),
        commission: Yup.string(),
        currency: Yup.string().required('Currency is required'),
        station_name: Yup.array()
            .min(1, 'Please select at least one station name.')  // Require at least one selection
            .of(Yup.string().required('A station name is required.')),
    });

    const { register, handleSubmit, setValue, getValues, reset, watch, control, formState: { errors } } = useForm({ resolver: yupResolver(rfidSchema) });

    const data = props.singleRfidRes && props.singleRfidRes.rfids[0] ? props.singleRfidRes.rfids[0] : {};
    // console.log("data", data);

    useEffect(() => {
        if (data) {
            setValue('rfid', data.rfid);
            setValue('commission', data.commission);
            setValue('currency', data.currency);
            if (data.station && data.station === 'All') {
                // console.log("All station is here...")
                setValue('station_name', [data.station]);
            } else if (data.station && data.station !== 'All') {
                // console.log("Except All,All stations is here...")
                setValue('station_name', [data.station]);
            } else {
                setValue('station_name', data.rfid_stations);
            }
            setIsAmountBased(data.amount_based);
            setIsRechargeAmountFree(data.free_charging);
            setRfidRechargeAmount(data.amount);
            setIskWhBased(data.kwh_based);
            setkWhUsage(data.kwh);
            setKWhAmount(data.kwh_amount);
            setIsTimeBased(data.time_based);
            setTime(data.time);
            setRfidType(data.type);
            setPostpaidDuration(data.sub_duration);
            setTimeRechargeAmount(data.time_amount)
            setTotalAmount(data.amount)
        }
    }, [data, setValue, watch]);
    const stationWatch = watch('station_name');
    // console.log("watch('station_name')", stationWatch)

    const stationOptions = stationWatch?.map(station => ({
        value: station,
        label: station
    }));

    const stationNameOptions = [
        { value: 'All', label: 'All' }, // Static option
        ...(props.evseList && props.evseList.data ? props.evseList.data
            .filter((item) => item.status !== 'Discovery' && item.publish !== false)
            .map(item => ({
                value: item.name,
                label: item.name
            }))
            : []) // Dynamic options
    ];

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={2}></Col>
                <Col xs={12} md={8}>
                    <Card className="tariffcard">
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}>
                            <b>{t("VIEW SINGLE RFID")}</b>
                        </Card.Text>
                        <Card.Body>
                            <form onSubmit={e => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>{t("RFID")} <span className='mandatory-field'>*</span></label>
                                    <input disabled type="text" className={`form-control gray-border`} name="rfid" id="floatingInputGridrfid" placeholder={t("RFID")} {...register("rfid")} />
                                </div>
                                <br />

                                <div className="form-floating">
                                    <label>{t("Commission date (dd/mm/yyyy)")}</label>
                                    <input disabled type="text" className={`form-control gray-border`} name="commission" id="floatingInputGridName" placeholder={t("Date")} {...register('commission')} />
                                </div>
                                <br />

                                <div className="row d-flex align-items-center">
                                    <div className='col-md'>
                                        <div className="mr-4">
                                            <input className="form-check-input" type="checkbox" id="amountBasedCheckbox" checked={isAmountBased} disabled />
                                            <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                {t("Amount Based")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div className="">
                                            <input className="form-check-input" type="checkbox" id="kiloWattBasedCheckbox" checked={iskWhBased} disabled />
                                            <label className="form-check-label" htmlFor="kiloWattBasedCheckbox">
                                                {t("kWh Based")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md'>
                                        <div>
                                            <input className="form-check-input" type="checkbox" id="timeBasedCheckbox" checked={isTimeBased} disabled />
                                            <label className="form-check-label" htmlFor="timeBasedCheckbox">
                                                {t("Time Based")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                {isAmountBased && (
                                    <>
                                        <div className=' pl-0 mb-4'>
                                            <div className="">
                                                <input className="form-check-input" style={{ width: "25rem" }} type="checkbox" id="amountBasedCheckbox" disabled checked={isRechargeAmountFree} />
                                                <label className="form-check-label" htmlFor="amountBasedCheckbox">
                                                    {t("IsFreeCharge?")}
                                                </label>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("RFID Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border`} name="rfidRechargeAmount" id="floatingInputRfidRechargeAmount" placeholder={t("RFID Recharge Amount")} value={totalAmount || rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Credit/Debit Amount")}</label>
                                                    <input type="number" className={`form-control gray-border`} name="creditDebitAmount" id="floatingInputCreditDebitAmount" placeholder={t("Credit/Debit Amount")} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-floating">
                                                    <label>{t("Total Amount")}</label>
                                                    <input type="text" className={`form-control gray-border`} name="totalAmount" id="floatingInputTotalAmount" placeholder={t("Total Amount")} value={totalAmount || rfidRechargeAmount} disabled />
                                                </div>
                                                <br />
                                            </Col>
                                        </Row>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <input type="text" className={`form-control gray-border`} name="paymentCollectedOffline" id="floatingInputPaymentCollectedOffline" placeholder={t("Is payment collected offline ?")} defaultValue={data?.offline_payment ? "Yes" : "No"} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {iskWhBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("kWh Usage")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border`} name="kiloWattUsage" id="floatingInputKiloWattUsage" placeholder={t("kWh Usage")} value={kWhUsage} disabled />
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border `}
                                                            name="kWhAmount"
                                                            id="floatingInputkWhAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={kWhAmount || ''}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <input type="text" className={`form-control gray-border`} name="paymentCollectedOffline" id="floatingInputPaymentCollectedOffline" placeholder={t("Is payment collected offline ?")} defaultValue={data?.offline_payment ? "Yes" : "No"} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {isTimeBased && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Time")} <span className='mandatory-field'>*</span></label>
                                                    <input type="number" className={`form-control gray-border `}
                                                        name="time" id="floatingInputTime"
                                                        placeholder={t("Free No. of Hours")}
                                                        value={time || ''}
                                                        disabled
                                                    />
                                                    <small className="form-text text-muted">
                                                        {t("Free No. Of Hours")}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='col-md'>
                                                    <div className="form-floating">
                                                        <label>{t("Recharge Amount")} <span className='mandatory-field'>*</span></label>
                                                        <input type="number"
                                                            className={`form-control gray-border`}
                                                            name="timeRechargeAmount"
                                                            id="floatingInputRechargeAmount"
                                                            placeholder={t("Recharge Amount")}
                                                            value={timeRechargeAmount}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md'>
                                                <div className="form-floating">
                                                    <label>{t("Is payment collected offline ?")} <span className='mandatory-field'>*</span></label>
                                                    <input type="text" className={`form-control gray-border`} name="paymentCollectedOffline" id="floatingInputPaymentCollectedOffline" placeholder={t("Is payment collected offline ?")} defaultValue={data?.offline_payment ? "Yes" : "No"} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <br />
                                <div className='row'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Tariff Currency")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select form-control `} name="currency" id="floatingSelectGridCurrency" disabled {...register("currency")}>
                                                <option value={data?.currency} hidden>{`${data?.currency ? data?.currency : ''}`}</option>
                                            </select>
                                        </div><br />
                                    </div>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>
                                                {t("Select Station Name")}
                                                <span className="mandatory-field">*</span>
                                            </label>
                                            < ReactSelect
                                                options={stationNameOptions}
                                                isMulti
                                                placeholder="Select Station Name"
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? 'grey' : 'grey',
                                                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                    }),
                                                    placeholder: (baseStyles) => ({
                                                        ...baseStyles,
                                                        whiteSpace: 'nowrap',
                                                    }),
                                                    input: base => ({
                                                        ...base,
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        '& input': {
                                                            border: 'none !important',
                                                        },
                                                    }),
                                                }}
                                                value={stationOptions}
                                                isOptionDisabled={() => true}
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>

                                </div>

                                {!isRechargeAmountFree &&
                                    <div className='row'>
                                        <div className='col-md'>
                                            <div className="form-floating">
                                                <label>{t("RFID Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select form-control`} name="rfidType" id="floatingSelectGridCurrency" disabled defaultValue={rfidType} >
                                                    <option value="" hidden disabled>{`${rfidType ? rfidType : t("Select RFID Type")}`}</option>
                                                    <option value="prepaid">{t("Prepaid")}</option>
                                                    <option value="postpaid">{t("Postpaid")}</option>
                                                </select>
                                            </div><br />
                                        </div>
                                        <div className={`${rfidType === 'postpaid' ? "col-md" : "col-12"}`}>
                                            {rfidType === 'postpaid' &&
                                                <div className="form-floating">
                                                    <label>
                                                        {t("Postpaid Duration")}
                                                        <span className="mandatory-field">*</span>
                                                    </label>
                                                    <select
                                                        className={`form-select form-control`}
                                                        name={`postpaidDuration`}
                                                        id="floatingSelectGridsubscriptionDuration"
                                                        disabled
                                                    >

                                                        <option value="" hidden>
                                                            {`${postpaidDuration ? postpaidDuration.charAt(0).toUpperCase() + postpaidDuration.slice(1) : t("Select Postpaid Duration")}`}
                                                        </option>
                                                        <option value={"weekly"}>{t("Weekly")}</option>
                                                        <option value={"monthly"}>{t("Monthly")}</option>
                                                        <option value={"yearly"}>{t("Yearly")}</option>
                                                    </select>
                                                    {postpaidDuration === '' && <div className="invalid-feedback">{"Postpaid Duration is required"}</div>}

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <br />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={2}></Col>
            </Row>
        </Container>
    );
};


const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        singleRfidRes: state.customer.fetchSingleRfid,
        evseList: state.evse.allevse,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleRfidData: (id) => dispatch(fetchSingleRfidApi(id)),
        allEvse: () => dispatch(allEvseAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSingleRfid);
