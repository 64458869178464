import { error } from "jquery";
import { FETCH_CHARGER_REPORT_API, CHARGING_SERVICE_URL, PREDICTIVE_URL, FETCH_DAILY_REPORT_API, FETCH_ALL_REPORT_API, RELEASE_TRANSACTION_API, REPORT_PERCENTAGE_CHANGE_API, REVENUE_SHARING_LIST_API, REVENUE_TOP10_TRANSACTION_API, FETCH_PLUG_UNPLUG_REPORT, FETCH_METER_DATA_BY_ID_API, COMMAND_URL, TOP_10_FAILED_TRANSACTION_API, TOP_10_COMPLETED_TRANSACTION_API, TOP_10_SUCCESS_TRANSACTION_API, FETCH_SUCCESS_TRANSACTION_API, FETCH_FAILED_TRANSACTION_API, FETCH_SINGLE_REPORT_RFID_API, WALLET_URL, FETCH_MY_REPORT_CARD_COUNT_API } from "../../components/config/config";


export const fetchChargerReport = () => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + FETCH_CHARGER_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data);
      dispatch({ type: 'LOAD_CHARGER_REPORT_API', payload: data })
    })
  }
}

export const fetchDailyReport = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_DAILY_REPORT_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_DAIL_REPORT', payload: data })
    })
  }
}

export const fetchMyReportReport = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'LOAD_ALL_REPORT', payload: data })
    })
  }
}

export const fetchReportCsvData = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_REPORT_CSV', payload: data })
    })
  }
}

export const fetchPlugUnplugsReport = (start, end, org) => {
  // console.log("start,end,org",start,end,org)
  const { token } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org)
    return fetch(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'GET_PLUG_UNPLUG_REPORT', payload: data })
    })
  }
}

export const fetchTop10Transaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + REVENUE_TOP10_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'TOP10_REVENUE_SHARING_LIST', payload: data })
    })
  }
}

export const releaseTransaction = (charging_id) => {
  // console.log(charging_id)
  // console.log(CHARGING_SERVICE_URL+RELEASE_TRANSACTION_API+"?charging_id="+charging_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + RELEASE_TRANSACTION_API + "?charging_id=" + charging_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      console.log("afterhit", data)
      dispatch({ type: 'RELEASE_TRANSACTION', payload: data })
    })
  }
}

// export const reportPercentageChange = () => {
//   const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
//   const operator = role === 'Admin' ? 'All' : organisation;
//   const bearer = 'basic ' + token
//   // console.log(token)
//   return dispatch => {
//     dispatch({ type: 'REPORT_LOADING', payload: '' })
//     return fetch(PREDICTIVE_URL + REPORT_PERCENTAGE_CHANGE_API + "?operator=" + operator, {
//       method: "GET",
//       headers: {
//         'Authorization': bearer,
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//       }
//     }).then(resp => resp.json()).then(data => {
//       // console.log("afterhit", data)
//       dispatch({ type: 'REPORT_PERCENTAGE_CHANGE', payload: data })
//     })
//   }
// }
export const reportPercentageChange = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token

  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' });

    try {
      const response = await fetch(
        `${PREDICTIVE_URL}${REPORT_PERCENTAGE_CHANGE_API}?operator=${operator}`,
        {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );

      if (!response.ok) {
        // If response is not ok, throw an error to be caught in the catch block
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("")
      dispatch({ type: 'REPORT_PERCENTAGE_CHANGE', payload: data });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching station session data:', error);
      // Optionally, dispatch an error action to handle errors in the UI
      // dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: error.message });
    }
  };
};

export const fetchRevenueSharingReport = (start, end) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + REVENUE_SHARING_LIST_API + "?start=" + start + "&end=" + end + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {

      dispatch({ type: 'REVENUE_SHARING_LIST', payload: data })
    })
  }
}

export const fetchMeterValueById = (charging_id, tag_id) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(COMMAND_URL + FETCH_METER_DATA_BY_ID_API + "?transaction_id=" + charging_id + "&idTag=" + tag_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data", data)
      dispatch({ type: 'FETCH_METER_DATA_BY_ID', payload: data })
    })
  }
}

export const fetchTop10FailedTransaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + TOP_10_FAILED_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("TOP10_FAILED_TRANSACTION_LIST",data)
      dispatch({ type: 'TOP10_FAILED_TRANSACTION_LIST', payload: data })
    })
  }
}

export const fetchTop10SuccessTransaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + TOP_10_SUCCESS_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("TOP10_SUCCESS_TRANSACTION_LIST",data)
      dispatch({ type: 'TOP10_SUCCESS_TRANSACTION_LIST', payload: data })
    })
  }
}

// success transaction Api 
export const fetchSuccessTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_SUCCESS_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_SUCCESS_TRANSACTION_LIST", data)
        dispatch({ type: 'FETCH_SUCCESS_TRANSACTION_LIST', payload: data })
      })
  }
}
// success all transaction api
export const fetchAllSuccessTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_SUCCESS_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_All_SUCCESS_TRANSACTION_LIST", data)
        dispatch({ type: 'FETCH_All_SUCCESS_TRANSACTION_LIST', payload: data })
      })
  }
}
// failed transaction api
export const fetchFailedTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit)
    return fetch(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_FAILED_TRANSACTION", data)
        dispatch({ type: 'FETCH_FAILED_TRANSACTION', payload: data })
      })
  }
}
export const fetchAllFailedTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(resp => resp.json())
      .then(data => {
        // console.log("FETCH_ALL_FAILED_TRANSACTION", data)
        dispatch({ type: 'FETCH_ALL_FAILED_TRANSACTION', payload: data })
      })
  }
}
export const fetchSingleReportRfidAction = (rfid) => {
  // console.log(rfid)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    try {
      const response = await fetch(`${WALLET_URL + FETCH_SINGLE_REPORT_RFID_API}?rfid=${rfid}`, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("data", data)
      dispatch({ type: 'FETCH_SINGLE_REPORT_RFID_API', payload: data });

    } catch (error) {
      console.error("Error fetching single report rfid:", error);
      dispatch({ type: 'FETCH_SINGLE_REPORT_RFID_API', payload: error.message });
    }
  }
}

// Fetch my report card count
export const fetchMyReportCardCountAction = (start, end, org) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  const bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_MY_REPORT_CARD_COUNT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_MY_REPORT_CARD_COUNT', payload: data })
    })
  }
}